import React, { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxDirections from '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions';
import '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions.css';

mapboxgl.accessToken = 'pk.eyJ1IjoiZWNvY3lib3Jncy10YTI3IiwiYSI6ImNtMGFvaDJwdDAweWcycG9ncDNtc2g1OWcifQ.YhkPkKrstKnsrXsZ0ZJp3Q';

const NavigationMap = ({ userCoordinates, parkCoordinates, transportMode }) => {
    const mapContainer = useRef(null);
    const map = useRef(null);
    const directions = useRef(null);

    useEffect(() => {
        if (!parkCoordinates || !userCoordinates) return;

        const parsedParkCoordinates = typeof parkCoordinates === 'string' 
            ? {
                latitude: parseFloat(parkCoordinates.replace(/[()]/g, '').split(',')[0]),
                longitude: parseFloat(parkCoordinates.replace(/[()]/g, '').split(',')[1]),
              }
            : parkCoordinates;

        if (
            isNaN(userCoordinates.latitude) || isNaN(userCoordinates.longitude) ||
            isNaN(parsedParkCoordinates.latitude) || isNaN(parsedParkCoordinates.longitude)
        ) {
            console.error('Error: Invalid coordinates provided', {
                userCoordinates,
                parsedParkCoordinates,
            });
            return;
        }

        if (!map.current) {
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/mapbox/streets-v11',
                center: [userCoordinates.longitude, userCoordinates.latitude],
                zoom: 12,
            });

            new mapboxgl.Marker({ color: 'blue' })
                .setLngLat([userCoordinates.longitude, userCoordinates.latitude])
                .addTo(map.current);

            new mapboxgl.Marker({ color: 'green' })
                .setLngLat([parsedParkCoordinates.longitude, parsedParkCoordinates.latitude])
                .addTo(map.current);

            directions.current = new MapboxDirections({
                accessToken: mapboxgl.accessToken,
                unit: 'metric',
                profile: `mapbox/${transportMode}`,
                interactive: true,
            });

            map.current.addControl(directions.current, 'top-left');

            directions.current.setOrigin([userCoordinates.longitude, userCoordinates.latitude]);
            directions.current.setDestination([parsedParkCoordinates.longitude, parsedParkCoordinates.latitude]);
        } else {
            directions.current.setProfile(`mapbox/${transportMode}`);
            directions.current.setOrigin([userCoordinates.longitude, userCoordinates.latitude]);
            directions.current.setDestination([parsedParkCoordinates.longitude, parsedParkCoordinates.latitude]);
        }

        return () => {
            if (map.current) {
                map.current.removeControl(directions.current);
                map.current.remove();
                map.current = null;
            }
        };
    }, [transportMode, userCoordinates, parkCoordinates]);

    return <div ref={mapContainer} style={{ width: '100%', height: '600px', borderRadius: '12px' }} />;
};

export default NavigationMap;
