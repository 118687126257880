import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ParkInfoPage = ({ park }) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/navigate', { state: { park } });
  };

  return (
    <Box p={4}>
      <Typography variant="h4">{park.name}</Typography>
      <Typography variant="body1" style={{ marginTop: '20px' }}>
        Category: {park.os_category}
      </Typography>
      <Typography variant="body1" style={{ marginTop: '10px' }}>
        Facilities: {park.facilities.join(', ')}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        style={{ marginTop: '20px' }}
        onClick={handleNavigate}
      >
        Navigate
      </Button>
    </Box>
  );
};

export default ParkInfoPage;
