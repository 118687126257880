import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Button, TextField, Paper, Typography, CssBaseline } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { styled } from '@mui/system'; 
import backgroundImage from '../assets/images/homepage.jpg'; 

const Root = styled('div')({
  height: '100vh',
  backgroundImage: `url(${backgroundImage})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
});

const Overlay = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.3)', 
  zIndex: 1,
});

const StyledPaper = styled(Paper)({
  zIndex: 2,
  padding: '32px',
  backgroundColor: 'rgba(255, 255, 255, 0.9)', 
  borderRadius: '10px',
  boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%', 
  maxWidth: '400px', 
});

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = () => {
    const correctUsername = 'TA27'; 
    const correctPassword = 'vrEcocyborgs'; 

    if (username === correctUsername && password === correctPassword) {
      localStorage.setItem('auth', 'true');
      navigate('/home'); 
    } else {
      alert('Invalid username or password');
    }
  };

  return (
    <Root>
      <CssBaseline />
      <Overlay />
      <StyledPaper elevation={6}>
        <Avatar sx={{ margin: 1, backgroundColor: '#0C7621' }}> 
          <LockOutlinedIcon sx={{ color: '#ffffff' }} /> 
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ marginTop: 3, marginBottom: 2 }}
            onClick={handleLogin}
          >
            Sign In
          </Button>
        </form>
      </StyledPaper>
    </Root>
  );
};

export default LoginPage;
