import React, { useEffect } from 'react';
import * as d3 from 'd3';

const styles = {
    body: {
        fontFamily: 'Arial, sans-serif',
        background: 'linear-gradient(to bottom, #4CAF50 40%, #f0f4f7 40%)',
        margin: 0,
        padding: '20px',
        textAlign: 'center'
    },
    header: {
        textAlign: 'center',
        marginBottom: '30px'
    },
    h2: {
        fontSize: '1.5em',
        color: '#F0FFFF',
        marginBottom: '20px'
    },
    chartsContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        gap: '20px'
    },
    chart: {
        width: '320px',
        backgroundColor: '#ffffff',
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
    },
    lineChart: {
        backgroundColor: '#ffffff'
    },
    pieChart: {
        backgroundColor: '#FFDEAD',
        color: '#000000'
    },
    barChart: {
        backgroundColor: '#C8E6C9'
    },
    chartHeader: {
        backgroundColor: '#33691E',
        color: '#ffffff',
        fontWeight: 'bold',
        padding: '5px',
        borderRadius: '5px',
        marginBottom: '10px'
    },
    chartDescription: {
        fontSize: '0.9em',
        color: '#666',
        marginTop: '10px'
    },
    legend: {
        listStyle: 'none',
        padding: 0,
        textAlign: 'left',
        marginTop: '10px'
    },
    legendItem: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '5px'
    },
    legendSpan: {
        display: 'inline-block',
        width: '20px',
        height: '20px',
        marginRight: '10px',
        borderRadius: '50%'
    },
    tooltip: {
        position: 'absolute',
        textAlign: 'center',
        padding: '8px',
        background: '#ffffff',
        border: '1px solid #ddd',
        borderRadius: '4px',
        pointerEvents: 'none',
        opacity: 0,
        transition: 'opacity 0.3s'
    }
};

const HomePageVis = () => {
    useEffect(() => {
        const pieData = [
            { label: "Improved Mood", value: 60 },
            { label: "Reduced Anxiety", value: 25 },
            { label: "Reduced Stress", value: 15 }
        ];

        const lineData1 = [
            { x: 1, y: 7 },
            { x: 2, y: 6 },
            { x: 3, y: 5 },
            { x: 4, y: 4 }
        ];
        const lineData2 = [
            { x: 1, y: 4 },
            { x: 2, y: 3 },
            { x: 3, y: 2 },
            { x: 4, y: 1 }
        ];

        const barData = [
            { activity: "Yoga", value: 50 },
            { activity: "Picnic", value: 120 },
            { activity: "Sports", value: 200 },
            { activity: "Volunteer", value: 80 }
        ];

        createPieChart('#pieChart', pieData);
        createLineChart('#lineChart', lineData1, lineData2);
        createBarChart('#barChart', barData);

        // 清理函数，确保在组件卸载时删除之前创建的图表
        return () => {
            d3.select('#pieChart').selectAll('*').remove();
            d3.select('#lineChart').selectAll('*').remove();
            d3.select('#barChart').selectAll('*').remove();
        };
    }, []);

    // Pie chart
    function createPieChart(container, data) {
        const width = 300;
        const height = 200;
        const radius = Math.min(width, height) / 2;

        const svg = d3.select(container)
            .append('svg')
            .attr('width', width)
            .attr('height', height)
            .append('g')
            .attr('transform', `translate(${width / 2}, ${height / 2})`);

        const pie = d3.pie().value(d => d.value);
        const arc = d3.arc().innerRadius(0).outerRadius(radius);
        const arcHover = d3.arc().innerRadius(0).outerRadius(radius + 10); // Effect for hover to enlarge slice

        const tooltip = d3.select('body').append('div')
            .attr('class', 'tooltip');

        // Define a color scale that maps each label to a specific color
        const color = d3.scaleOrdinal()
            .domain(data.map(d => d.label)) // Define the domain based on the labels
            .range(['#4CAF50', '#FF9800', '#8BC34A']); // Define the range with colors matching the legend

        svg.selectAll('path')
            .data(pie(data))
            .enter()
            .append('path')
            .attr('d', arc)
            .attr('fill', d => color(d.data.label)) // Use the color scale to set the fill color
            .attr("transform", "rotate(-180)") // Initial rotation for animation effect
            .on('mouseover', function(event, d) {
                // Enlarge the current slice on hover
                d3.select(this)
                    .transition()
                    .duration(200)
                    .attr('d', arcHover);
                
                // Dim other slices
                svg.selectAll('path')
                    .filter(p => p !== d)
                    .transition()
                    .duration(200)
                    .style('opacity', 0.5);

                tooltip.transition().duration(200).style('opacity', .9);
                tooltip.html(`${d.data.label}: ${d.data.value}`)
                    .style('left', (event.pageX) + 'px')
                    .style('top', (event.pageY - 28) + 'px');
            })
            .on('mouseout', function() {
                // Restore all slices to original size and opacity
                svg.selectAll('path')
                    .transition()
                    .duration(200)
                    .attr('d', arc)
                    .style('opacity', 1);

                tooltip.transition().duration(500).style('opacity', 0);
            })
            .transition()
            .duration(2000)
            .attr("transform", "rotate(0)"); // Complete rotation to place slices properly
    }

    function createLineChart(container, data1, data2) {
        const width = 300;
        const height = 200;
        const margin = { top: 20, right: 20, bottom: 50, left: 50 };

        const svg = d3.select(container)
            .append('svg')
            .attr('width', width)
            .attr('height', height)
            .append('g')
            .attr('transform', `translate(${margin.left},${margin.top})`);

        const x = d3.scaleLinear().domain([0, 5]).range([0, width - margin.left - margin.right]);
        const y = d3.scaleLinear().domain([0, 8]).range([height - margin.top - margin.bottom, 0]);

        svg.append('g')
            .attr('transform', `translate(0, ${height - margin.top - margin.bottom})`)
            .call(d3.axisBottom(x))
            .append('text')
            .attr('x', (width - margin.left - margin.right) / 2)
            .attr('y', 40)
            .attr('fill', '#000')
            .text('Weekly exercise hours');

        svg.append('g')
            .call(d3.axisLeft(y))
            .append('text')
            .attr('transform', 'rotate(-90)')
            .attr('x', -(height - margin.top - margin.bottom) / 2)
            .attr('y', -40)
            .attr('fill', '#000')
            .text('Disease risk level');

        const line1 = d3.line().x(d => x(d.x)).y(d => y(d.y));
        const line2 = d3.line().x(d => x(d.x)).y(d => y(d.y));

        svg.append('path')
            .datum(data1)
            .attr('fill', 'none')
            .attr('stroke', 'green')
            .attr('stroke-width', 2)
            .attr('d', line1);

        svg.append('path')
            .datum(data2)
            .attr('fill', 'none')
            .attr('stroke', 'orange')
            .attr('stroke-width', 2)
            .attr('d', line2);
    }

    function createBarChart(container, data) {
        const width = 300;
        const height = 200;
        const margin = { top: 20, right: 20, bottom: 50, left: 80 };

        const svg = d3.select(container)
            .append('svg')
            .attr('width', width)
            .attr('height', height)
            .append('g')
            .attr('transform', `translate(${margin.left},${margin.top})`);

        const x = d3.scaleLinear().domain([0, 200]).range([0, width - margin.left - margin.right]);
        const y = d3.scaleBand().domain(data.map(d => d.activity)).range([0, height - margin.top - margin.bottom]).padding(0.1);

        svg.append('g')
            .attr('transform', `translate(0, ${height - margin.top - margin.bottom})`)
            .call(d3.axisBottom(x))
            .append('text')
            .attr('x', (width - margin.left - margin.right) / 2)
            .attr('y', 40)
            .attr('fill', '#000')
            .text('Number of Participants');

        svg.append('g')
            .call(d3.axisLeft(y))
            .append('text')
            .attr('transform', 'rotate(-90)')
            .attr('x', -(height - margin.top - margin.bottom) / 2)
            .attr('y', -60)
            .attr('fill', '#000')
            .text('Activity Type');

        svg.selectAll('.bar')
            .data(data)
            .enter()
            .append('rect')
            .attr('class', 'bar')
            .attr('y', d => y(d.activity))
            .attr('height', y.bandwidth())
            .attr('x', 0)
            .attr('width', d => x(d.value))
            .attr('fill', 'goldenrod');
    }

    return (
        <div style={styles.body}>
            <div style={styles.header}>
                <h2 style={styles.h2}>Experience the Multifaceted Health Benefits of Green Spaces</h2>
            </div>
            <div style={styles.chartsContainer}>
                <div style={{ ...styles.chart, ...styles.lineChart }} id="lineChartContainer">
                    <div style={styles.chartHeader}>Reduce Disease Risk</div>
                    <div id="lineChart"></div>
                    <p style={styles.chartDescription}>Heart Health Boost: Engaging in outdoor activities, like walking or running, can significantly decrease the likelihood of heart disease and stroke.</p>
                </div>
                <div style={{ ...styles.chart, ...styles.pieChart }} id="pieChartContainer">
                    <div style={styles.chartHeader}>Reduce Stress, Anxiety</div>
                    <div id="pieChart"></div>
                    <ul style={styles.legend}>
                        <li style={styles.legendItem}>
                            <span style={{ ...styles.legendSpan, backgroundColor: '#4CAF50' }}></span> Improved Mood (60%)
                        </li>
                        <li style={styles.legendItem}>
                            <span style={{ ...styles.legendSpan, backgroundColor: '#FF9800' }}></span> Reduced Anxiety (25%)
                        </li>
                        <li style={styles.legendItem}>
                            <span style={{ ...styles.legendSpan, backgroundColor: '#8BC34A' }}></span> Reduced Stress (15%)
                        </li>
                    </ul>
                    <p style={styles.chartDescription}>Mental Health Benefits: Spending time in natural surroundings can effectively reduce anxiety and depression symptoms, contributing to overall mental well-being.</p>
                </div>
                <div style={{ ...styles.chart, ...styles.barChart }} id="barChartContainer">
                    <div style={styles.chartHeader}>Community Building</div>
                    <div id="barChart"></div>
                    <p style={styles.chartDescription}>Parks provide a platform for individuals to connect with their communities, participate in group activities, and forge new friendships.</p>
                </div>
            </div>
        </div>
    );
};

export default HomePageVis;