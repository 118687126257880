import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, Button, Dialog, DialogContent } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import NavigationMap from './NavigationMap';
import mapboxgl from 'mapbox-gl';
import FlipCard from './FlipCard';
import MapboxDirections from '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions';
import _ from 'lodash';

mapboxgl.accessToken = 'pk.eyJ1IjoiZWNvY3lib3Jncy10YTI3IiwiYSI6ImNtMGFvaDJwdDAweWcycG9ncDNtc2g1OWcifQ.YhkPkKrstKnsrXsZ0ZJp3Q';  // Replace with your Mapbox token

const NavigationPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { park, userCoordinates } = location.state || {};
    const [carbonFootprintSavedWalking, setCarbonFootprintSavedWalking] = useState(0);
    const [carbonFootprintSavedCycling, setCarbonFootprintSavedCycling] = useState(0);
    const [treesEquivalentWalking, setTreesEquivalentWalking] = useState(0);
    const [treesEquivalentCycling, setTreesEquivalentCycling] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);

    const parkCoordinates = park?.coords
        ? {
            latitude: parseFloat(park.coords.split(',')[0].replace(/[()]/g, '')),
            longitude: parseFloat(park.coords.split(',')[1].replace(/[()]/g, '')),
        }
        : null;

    // Calculate CO2 saved and trees equivalent
    const calculateFootprintAndTrees = (distance, mode) => {
        const co2PerKm = {
            walking: 0,
            cycling: 0.02,
            driving: 0.21,
        };

        const co2Saved = co2PerKm['driving'] - co2PerKm[mode];
        const totalCO2Saved = co2Saved * distance;
        const treesSaved = totalCO2Saved / 22;

        if (mode === 'walking') {
            setCarbonFootprintSavedWalking(totalCO2Saved.toFixed(2));
            setTreesEquivalentWalking(treesSaved.toFixed(2));
        } else if (mode === 'cycling') {
            setCarbonFootprintSavedCycling(totalCO2Saved.toFixed(2));
            setTreesEquivalentCycling(treesSaved.toFixed(2));
        }
    };

    // Fetch route information from Mapbox
    const fetchRouteInfoFromMapbox = useCallback(
        _.debounce(async (mode) => {
            const directions = new MapboxDirections({
                accessToken: mapboxgl.accessToken,
                profile: `mapbox/${mode}`,
            });

            directions.setOrigin([userCoordinates.longitude, userCoordinates.latitude]);
            directions.setDestination([parkCoordinates.longitude, parkCoordinates.latitude]);

            directions.on('route', (e) => {
                const route = e.route[0];
                const distanceKm = route.distance / 1000;

                calculateFootprintAndTrees(distanceKm, mode);
            });

            directions.on('error', (err) => {
                console.error('Error fetching route info from Mapbox:', err);
            });
        }, 300),
        [userCoordinates, parkCoordinates]
    );

    useEffect(() => {
        fetchRouteInfoFromMapbox('walking');
        fetchRouteInfoFromMapbox('cycling');
    }, [fetchRouteInfoFromMapbox]);

    const handleCompleteTrip = () => {
        setOpenDialog(true);
        setTimeout(() => {
            setOpenDialog(false);
            navigate('/');
        }, 3000);
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            sx={{ minHeight: '100vh', backgroundColor: '#F1F8E9', pt: 4 }} // Added padding-top to lower the title
        >
            {/* Title */}
            <Typography
                variant="h4"
                sx={{
                    fontWeight: 'bold',
                    color: '#2E7D32',
                    textAlign: 'center',
                    mb: 2, // Margin below the title
                    letterSpacing: '1px',
                    paddingX: 2, // Added padding on the left and right
                }}
            >
                Navigation to {park?.name}
            </Typography>

            {/* Content */}
            <Box
                display="flex"
                flexDirection={{ xs: 'column', md: 'row' }}
                p={2} // Padding around the columns
                gap={2} // Gap between the map and the right column
                sx={{ flex: 1, backgroundColor: '#F1F8E9' }} // Set flex to make the content fill the remaining height
            >
                {/* Left Side: Map */}
                <Box
                    width={{ xs: '100%', md: '70%' }}
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-start"
                    sx={{ height: 'auto', padding: 0, margin: 0 }} // Ensure no padding or margins add extra space
                >
                    <NavigationMap
                        userCoordinates={userCoordinates}
                        parkCoordinates={parkCoordinates}
                        transportMode="walking"
                        style={{ flex: 1, height: '65vh', marginBottom: 0 }} // Set height and remove margin below
                    />
                    
                </Box>

                {/* Right Side: Instructions, Flip Cards, and Buttons */}
                <Box
                    width={{ xs: '100%', md: '30%' }}
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-start"
                    alignItems="center"
                    sx={{
                        backgroundColor: '#E8F5E9',
                        p: 2,
                        borderRadius: '12px',
                        boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.1)',
                        height: '65vh', // Match the height of the map for consistency
                        padding: 0, // Ensure no padding adds extra space
                        marginBottom: 0, // Remove bottom margin
                    }}
                >
                    {/* Content Wrapper to Control Width */}
                    <Box sx={{ width: '80%', textAlign: 'center' }}>
                        {/* Instructions */}
                        <Typography
                            variant="body1"
                            sx={{
                                color: '#2E7D32',
                                mb: 1, // Adjust margin to align with the top of the map
                                fontWeight: 'bold',
                            }}
                        >
                            Hover over each card to see how much CO2 you can save by walking or cycling.
                        </Typography>

                        {/* Flip Card for Walking */}
                        <FlipCard
                            frontText="How much CO2 can you save by walking 2 KM a day?"
                            backText={`You saved 0.42 kg CO2, equivalent to saving Approximately 7 trees in 1 year.`}
                            sx={{ mb: 1 }} // Small margin to space out the cards
                        />

                        {/* Flip Card for Cycling */}
                        <FlipCard
                            frontText="How much CO2 can you save by cycling 2 KM a day?"
                            backText={`You saved 0.38 kg CO2, equivalent to saving Approximately 6 trees in 1 year.`}
                            sx={{ mb: 1 }} // Small margin to space out the cards
                        />

                        {/* Buttons */}
                        <Box width="100%" display="flex" justifyContent="space-between" mt={2}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => navigate(-1)}
                                sx={{
                                    backgroundColor: '#2E7D32',
                                    color: 'white',
                                    p: 1,
                                    fontWeight: 'bold',
                                    width: '45%',
                                }}
                            >
                                Back
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleCompleteTrip}
                                sx={{
                                    backgroundColor: '#388E3C',
                                    color: 'white',
                                    p: 1,
                                    fontWeight: 'bold',
                                    width: '45%',
                                }}
                            >
                                Complete Trip
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>

            {/* Complete Trip Dialog */}
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogContent sx={{ textAlign: 'center', p: 2 }}>
                    <Typography variant="h6" color="textPrimary" gutterBottom>
                        Enjoy your time at {park?.name}!
                    </Typography>
                    <Typography variant="body1" color="textSecondary">
                        Thank you for choosing a sustainable way to reach your destination.
                    </Typography>
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default NavigationPage;
