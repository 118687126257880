import React from 'react';
import { Box, Typography } from '@mui/material';
import './FlipCard.css'; // Ensure you have this CSS file for the flip card styling

const FlipCard = ({ frontText, backText }) => (
    <Box className="flip-card" style={{ width: '100%', marginBottom: '10px' }}>
        <Box className="flip-card-inner">
            <Box className="flip-card-front">
                <Typography variant="h6" style={{ color: '#FFFFFF', textAlign: 'center', padding: '20px' }}>
                    {frontText}
                </Typography>
            </Box>
            <Box className="flip-card-back">
                <Typography variant="h6" style={{ color: '#FFFFFF', textAlign: 'center', padding: '20px' }}>
                    {backText}
                </Typography>
            </Box>
        </Box>
    </Box>
);

export default FlipCard;
