import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Box,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Button,
  Autocomplete,
  TextField,
  Drawer,
  IconButton,
  Grid
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import GreenSpaceMap from './GreenSpaceMap';
import bannerImage from '../assets/images/banner.png';
import SearchResults from './SearchResults';
import CloseIcon from '@mui/icons-material/Close';
import loaderGif from '../assets/images/research2.gif';

import currentLocationIcon from '../assets/images/current_location.png';
import parkLocationIcon from '../assets/images/park_location.png';
import playgroundLocationIcon from '../assets/images/playground_location.png';
import sportLocationIcon from '../assets/images/sport_location.png';

const MAPBOX_TOKEN = 'pk.eyJ1IjoiZWNvY3lib3Jncy10YTI3IiwiYSI6ImNtMGFvaDJwdDAweWcycG9ncDNtc2g1OWcifQ.YhkPkKrstKnsrXsZ0ZJp3Q';

const NearbyGreenSpaces = () => {
  const mapRef = useRef(null);
  const [location, setLocation] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [coordinates, setCoordinates] = useState({ latitude: -37.8136, longitude: 144.9631 });
  const [greenSpaceType, setGreenSpaceType] = useState('');
  const [facilities, setFacilities] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [greenSpaces, setGreenSpaces] = useState([]);
  const [loading, setLoading] = useState(false);
  const [placeIds, setPlaceIds] = useState([]);
  const navigate = useNavigate();

  const facilityOptions = {
    'Park and Garden': ['Drinking Fountain', 'Bicycle rails', 'Picnic Setting', 'Playground', 'Toilet'],
    'Sportsfield': ['Drinking Fountain', 'Bicycle rails', 'Toilet', 'Picnic Setting', 'Seat'],
    'Playground': ['Swing', 'Shade', 'Slide', 'PicnicSetting', 'Toilet'],
  };

  const fetchSuggestions = async (input) => {
    if (!input) {
      setSuggestions([]);
      return;
    }

    // Bounding box for Melbourne (approximate coordinates)
    const bbox = '144.5937,-38.4339,145.5126,-37.5113'; // Southwest lon/lat, Northeast lon/lat

    const response = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(input)}.json?bbox=${bbox}&limit=5&access_token=${MAPBOX_TOKEN}`
    );
    const data = await response.json();
    if (data.features) {
      setSuggestions(data.features.map((feature) => ({
        label: feature.place_name,
        coordinates: feature.center
      })));
    }
  };


  const reverseGeocode = async (latitude, longitude) => {
    const response = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${MAPBOX_TOKEN}`
    );
    const data = await response.json();
    if (data.features && data.features.length > 0) {
      return data.features[0].place_name;
    }
    return 'Current Location';
  };

  const handleLocationChange = (event, newValue) => {
    const locationName = newValue?.label || '';
    setLocation(locationName);
    if (locationName && newValue?.coordinates) {
      setCoordinates({ latitude: newValue.coordinates[1], longitude: newValue.coordinates[0] });
      fetchNearbyGreenSpaces(locationName);
    }
  };

  const handleUseCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          setCoordinates({ latitude, longitude });
          const placeName = await reverseGeocode(latitude, longitude);
          setLocation(placeName);

          fetchNearbyGreenSpaces(placeName);
        },
        (error) => {
          console.error('Error fetching location:', error);
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
      );
    } else {
      alert('Geolocation is not supported by your browser.');
    }
  };

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.flyTo({
        center: [coordinates.longitude, coordinates.latitude],
        zoom: 12,
        essential: true
      });
    }
  }, [coordinates]);

  const fetchNearbyGreenSpaces = async (locationName) => {
    try {
        const response = await fetch(`https://api.greenfinderinmelb.me/api/find-nearby-parks/?location=${encodeURIComponent(locationName)}&latitude=${coordinates.latitude}&longitude=${coordinates.longitude}`);
        const data = await response.json();

        if (Array.isArray(data) && data.length > 0) {
            setGreenSpaces(data);
            setPlaceIds(data.map(space => space.id));
        } else {
            console.log('No green spaces available for filtering.');
            setGreenSpaces([]);  // Set to empty array to trigger "no results" message
        }
    } catch (error) {
        console.error('Error fetching nearby green spaces:', error);
        setGreenSpaces([]);  // Handle errors by setting to an empty array
    }
};


  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const toRadians = (degree) => degree * (Math.PI / 180);
    const R = 6371;
    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;
    return distance;
  };

  const fetchFilteredGreenSpaces = useCallback(async () => {
    if (placeIds.length === 0) {
        console.log('No green spaces available for filtering.');
        setGreenSpaces([]);  // Set to empty array to trigger "no results" message
        return;
    }
  
    const categoryMap = {
      'Park and Garden': 'Parks and gardens',
      'Sportsfield': 'Sportsfields and organised recreation',
      'Playground': 'Playgrounds',
    };

    const selectedCategory = categoryMap[greenSpaceType];

    try {
        const response = await fetch(
            `https://api.greenfinderinmelb.me/api/filter-space-type/?category=${encodeURIComponent(selectedCategory)}&place_ids=${JSON.stringify(placeIds)}`
        );
        const data = await response.json();

        if (Array.isArray(data) && data.length > 0) {
            const filteredData = data.filter(space => space.name.trim() !== '' && space.category === selectedCategory);
            setGreenSpaces(filteredData);
        } else {
            console.log('No green spaces available after filtering.');
            setGreenSpaces([]);  // Set to empty array to trigger "no results" message
        }
    } catch (error) {
        console.error('Error fetching filtered results:', error);
        setGreenSpaces([]);  // Handle errors by setting to an empty array
    }
}, [greenSpaceType, placeIds]);

  useEffect(() => {
    if (greenSpaceType) {
      fetchFilteredGreenSpaces();
    }
  }, [greenSpaceType, fetchFilteredGreenSpaces]);

  const handleGreenSpaceTypeChange = (event) => {
    setGreenSpaceType(event.target.value);
    setFacilities([]);
  };

  const handleFacilityChange = (event) => {
    const value = event.target.name;
    setFacilities((prev) =>
      prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value]
    );
  };

  const handleSearch = async () => {
    if (placeIds.length === 0) {
        console.log('No green spaces available for filtering.');
        return;
    }

    setLoading(true);
    const categoryMap = {
        'Park and Garden': 'Parks and gardens',
        'Sportsfield': 'Sportsfields and organised recreation',
        'Playground': 'Playgrounds',
    };

    const selectedCategory = categoryMap[greenSpaceType];

    let url = `https://api.greenfinderinmelb.me/api/filter-amenities/?place_ids=[${placeIds.join(',')}]&category=${encodeURIComponent(selectedCategory)}`;

    if (facilities.length === 0) {
        url += `&amenity_filters=`;
    } else {
        const amenityFiltersQueryString = facilities.map(facility => `amenity_filters=${encodeURIComponent(facility)}`).join('&');
        url += `&${amenityFiltersQueryString}`;
    }

    try {
        const response = await fetch(url);
        const data = await response.json();

        if (data.length > 0) {
            const filteredData = data.filter(space => space.name.trim() !== '' && space.category === selectedCategory);

            const sortedData = filteredData.map(space => {
                const coordsArray = space.coords.replace(/[()]/g, '').split(',').map(Number);
                const latitude = parseFloat(coordsArray[0]);
                const longitude = parseFloat(coordsArray[1]);
                const distance = calculateDistance(coordinates.latitude, coordinates.longitude, latitude, longitude);

                const matchedAmenities = space.amenities ? JSON.parse(space.amenities.replace(/'/g, '"')) : [];

                return { ...space, latitude, longitude, distance, matchedAmenities };
            }).sort((a, b) => b.matchedAmenities.length - a.matchedAmenities.length || a.distance - b.distance);

            setGreenSpaces(sortedData.slice(0, 10));

            // Calculate the bounding box of the found green spaces
            const lngs = sortedData.map(space => space.longitude);
            const lats = sortedData.map(space => space.latitude);

            const bounds = [
                [Math.min(...lngs), Math.min(...lats)],
                [Math.max(...lngs), Math.max(...lats)]
            ];

            // Adjust the map view to fit the bounds
            if (mapRef.current) {
                mapRef.current.fitBounds(bounds, {
                    padding: { top: 20, bottom: 20, left: 20, right: 20 },
                    maxZoom: 14, // Ensure it zooms in to a reasonable level
                    duration: 1000  // Smooth animation
                });
            }
        } else {
            setGreenSpaces([]);
            console.log('No results found after applying filters.');
        }

        setDrawerOpen(true);
    } catch (error) {
        console.error('Error fetching filtered results:', error);
    } finally {
        setLoading(false);
    }
};


  const handleNavigate = (park) => {
    navigate('/navigate', {
        state: {
            park,
            userCoordinates: coordinates // Pass user's current coordinates
        }
    });
  };


  return (
    <Box p={4}>
      <Box mb={4}>
        <img src={bannerImage} alt="Green Spaces Banner" style={{ width: '70%', height: 'auto' }} />
      </Box>

      <Grid container spacing={2}>
        {/* Map Section */}
        <Grid item xs={12} md={6}>
        <GreenSpaceMap
          ref={mapRef}
          coordinates={coordinates}
          greenSpaces={greenSpaces}
          currentLocationIcon={currentLocationIcon}
          parkLocationIcon={parkLocationIcon}
          playgroundLocationIcon={playgroundLocationIcon}
          sportLocationIcon={sportLocationIcon}
          style={{ height: '600px' }}  // Fixed height for the map
        />

        </Grid>

        {/* Search and Filter Section */}
        <Grid item xs={12} md={6}>
          <Box mt={4} display="flex" alignItems="center" gap={2}>
            <Autocomplete
              freeSolo
              options={suggestions}
              value={location}
              onInputChange={(event, value) => fetchSuggestions(value)}
              onChange={handleLocationChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Location"
                  placeholder="Please enter your current location or your preferred location"
                  variant="outlined"
                  fullWidth
                />
              )}
              style={{ flexGrow: 1 }}
            />
            <Button
              variant="outlined"
              onClick={handleUseCurrentLocation}
              sx={{
                color: '#0C7621',
                borderColor: '#0C7621',
                '&:hover': {
                  backgroundColor: '#2ABB48',
                  color: 'white',
                  borderColor: '#2ABB48',
                },
              }}
            >
              Use Current Location
            </Button>
          </Box>

          {location && (
            <>
              <FormControl fullWidth variant="outlined" style={{ marginBottom: '20px', marginTop: '20px' }}>
                <InputLabel id="green-space-type-label">Select Green Space Type</InputLabel>
                <Select
                  labelId="green-space-type-label"
                  value={greenSpaceType}
                  onChange={handleGreenSpaceTypeChange}
                  label="Select Green Space Type"
                >
                  <MenuItem value="Park and Garden">Park and Garden</MenuItem>
                  <MenuItem value="Sportsfield">Sportsfield</MenuItem>
                  <MenuItem value="Playground">Playground</MenuItem>
                </Select>
              </FormControl>

              {greenSpaceType && (
                <>
                  <Box>
                    <h3>Choose the facilities you want in your green space:</h3>
                    {facilityOptions[greenSpaceType].map((facility) => (
                      <FormControlLabel
                        key={facility}
                        control={
                          <Checkbox
                            checked={facilities.includes(facility)}
                            onChange={handleFacilityChange}
                            name={facility}
                          />
                        }
                        label={facility}
                      />
                    ))}
                  </Box>

                  <Button
                    variant="contained"
                    onClick={handleSearch}
                    sx={{
                      marginTop: '20px',
                      backgroundColor: '#0C7621',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: '#2ABB48',
                      },
                    }}
                    fullWidth
                  >
                    Search
                  </Button>
                </>
              )}
            </>
          )}
        </Grid>
      </Grid>

      {loading && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
          position="fixed"
          top="0"
          left="0"
          width="100%"
          zIndex="1000"
          backgroundColor="rgba(255, 255, 255, 0.8)"
        >
          <img
            src={loaderGif}
            alt="Loading..."
            style={{ width: '150px', height: '150px' }}
          />
        </Box>
      )}

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        PaperProps={{
          sx: { width: '50%' }
        }}
      >
        <Box display="flex" justifyContent="flex-end" p={1}>
          <IconButton onClick={() => setDrawerOpen(false)} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Box>

        <SearchResults greenSpaces={greenSpaces} onSelect={handleNavigate} />
      </Drawer>
    </Box>
  );
};

export default NearbyGreenSpaces;
