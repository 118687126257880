import React from 'react';
import './App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Create from './components/Create';
import NearbyGreenSpaces from './components/NearbyGreenSpaces';
import ExploreGreenSpace from './components/ExploreGreenSpace';
import ParkInfoPage from './components/ParkInfoPage';
import NavigationPage from './components/NavigationPage';
import PersistentDrawer from './components/PersistentDrawer';
import LoginPage from './components/LoginPage';
import PrivateRoute from './components/PrivateRoute';

function App() {
  return (
    <div className="App">
      <Routes>
        {/* Public Route for Login */}
        <Route path="/login" element={<LoginPage />} />

        {/* Protected Routes */}
        <Route
          path="*"
          element={
            <PrivateRoute
              element={
                <PersistentDrawer
                  content={
                    <Routes>
                      <Route path="/home" element={<Home />} />
                      <Route path="/about" element={<About />} />
                      <Route path="/create" element={<Create />} />
                      <Route path="/nearby-green-spaces" element={<NearbyGreenSpaces />} />
                      <Route path="/explore-green-space" element={<ExploreGreenSpace />} />
                      <Route path="/park-info" element={<ParkInfoPage />} />
                      <Route path="/navigate" element={<NavigationPage />} />
                      <Route path="*" element={<Navigate to="/home" />} />
                    </Routes>
                  }
                />
              }
            />
          }
        />

        {/* Redirect any unmatched routes to login */}
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </div>
  );
}

export default App;
