import * as React from 'react';
import PropTypes from 'prop-types';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Fade from '@mui/material/Fade';
import useScrollTrigger from '@mui/material/useScrollTrigger';


import HomeIcon from '@mui/icons-material/Home';
import NatureIcon from '@mui/icons-material/Nature';
import ExploreIcon from '@mui/icons-material/Explore';

import { Link, useLocation } from 'react-router-dom';
import logo from '../assets/images/logo.png';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

function ScrollTop(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Fade>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element,
  window: PropTypes.func,
};

export default function PersistentDrawer(props) {
  const { content } = props;
  const location = useLocation();
  const path = location.pathname;

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} sx={{ backgroundColor: '#1d4b0b' }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Link to="/" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
            <img src={logo} alt="Green Finder Logo" style={{ height: '70px', marginRight: '10px' }} />
            <Typography variant="h6" noWrap component="div" 
              sx={{ fontWeight: 'bold',
                fontFamily: 'Chalkduster',  
                fontSize: {
                xs: '0.75rem', // small screens
                sm: '0.875rem', // medium screens
                md: '1rem', // large screens
                lg: '1.25rem', // extra large screens
                },
                whiteSpace: 'nowrap',
              }}>

              Green Finder in the City of Melbourne
            </Typography>
          </Link>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: '#0C7621', 
            color: 'white',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemButton
              component={Link}
              to="/"
              selected={"/" === path}
              sx={{
                backgroundColor: "/" === path ? 'linear-gradient(45deg, #66bb6a, #1c5c1f)' : 'inherit',
                color: "/" === path ? '#ffffff' : 'inherit',
                fontWeight: "/" === path ? 'bold' : 'normal',
                borderRadius: '8px',
                boxShadow: "/" === path ? '3px 4px 6px rgba(0, 0, 0, 0.4), 0px 0px 6px rgba(0, 0, 0, 0.4)' : 'none',
                '&:hover': {
                  backgroundColor: "#1A5D27",
                },
                '& .MuiListItemIcon-root': {
                  color: "/" === path ? '#ffffff' : 'inherit',
                },
              }}
            >
              <ListItemIcon>
                <HomeIcon sx={{ color: 'inherit' }} />
              </ListItemIcon>
              <ListItemText primary={'Home'} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              component={Link}
              to="/nearby-green-spaces"
              selected={"/nearby-green-spaces" === path}
              sx={{
                backgroundColor: "/nearby-green-spaces" === path ? 'linear-gradient(45deg, #66bb6a, #1c5c1f)' : 'inherit',
                color: "/nearby-green-spaces" === path ? '#ffffff' : 'inherit',
                fontWeight: "/nearby-green-spaces" === path ? 'bold' : 'normal',
                borderRadius: '8px',
                boxShadow: "/nearby-green-spaces" === path ? '3px 4px 6px rgba(0, 0, 0, 0.4), 0px 0px 6px rgba(0, 0, 0, 0.4)' : 'none',
                '&:hover': {
                  backgroundColor: "#1A5D27",
                },
                '& .MuiListItemIcon-root': {
                  color: "/nearby-green-spaces" === path ? '#ffffff' : 'inherit',
                },
              }}
            >
              <ListItemIcon>
                <ExploreIcon sx={{ color: 'white' }} />
              </ListItemIcon>
              <ListItemText primary={'Find Nearby Green Spaces'} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              component={Link}
              to="/explore-green-space"
              selected={"/explore-green-space" === path}
              sx={{
                backgroundColor: "/explore-green-space" === path ? 'linear-gradient(45deg, #66bb6a, #1c5c1f)' : 'inherit',
                color: "/explore-green-space" === path ? '#ffffff' : 'inherit',
                fontWeight: "/explore-green-space" === path ? 'bold' : 'normal',
                borderRadius: '8px',
                boxShadow: "/explore-green-space" === path ? '3px 4px 6px rgba(0, 0, 0, 0.4), 0px 0px 6px rgba(0, 0, 0, 0.4)' : 'none',
                '&:hover': {
                  backgroundColor: "#1A5D27",
                },
                '& .MuiListItemIcon-root': {
                  color: "/explore-green-space" === path ? '#ffffff' : 'inherit',
                },
              }}
            >
              <ListItemIcon>
                <NatureIcon sx={{ color: 'white' }} />
              </ListItemIcon>
              <ListItemText primary={'Explore your green space'} />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Main open={open}>
        <DrawerHeader id="back-to-top-anchor" />
        {content}
      </Main>
      <ScrollTop {...props}>
        <Fab size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </Box>
  );
}
