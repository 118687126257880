import React from 'react';
import { Button, Box, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import parkImage from '../assets/images/forest.png';
import Banner from '../components/Banner';
import InfoCardsHome from '../components/InfoCardsHome';

const Home = () => {
  const navigate = useNavigate();

  const handleFindNearbyClick = () => {
    navigate('/nearby-green-spaces');
  };

  return (
    <Box
      sx={{
        backgroundColor: 'rgba(150, 189, 149 , 0.5)', 
        width: '100%', 
        minHeight: '100vh', 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        padding: '20px', 
      }}
    >
      <Container 
        maxWidth="md"  
        sx={{ 
          marginTop: '20px', 
          marginBottom: '20px', 
          textAlign: 'center',
          flex: 1, 
        }}
      >
        <Banner>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center', 
              alignItems: 'center', 
              flexDirection: 'column', 
              textAlign: 'center', 
            }}
          >
            <Button
              variant="contained"
              onClick={handleFindNearbyClick}
              sx={{
                marginTop: '20px',
                backgroundColor: '#0C7621',
                color: 'white',
                fontWeight: 'bold',
                padding: '10px 20px', 
                '&:hover': { backgroundColor: '#2ABB48' },
                fontSize: {
                  xs: '0.75rem !important', // small screens
                  sm: '0.8rem !important',  // medium screens
                  md: '1rem !important',    // large screens
                  lg: '1.25rem !important', // extra large screens
                },
                whiteSpace: 'nowrap',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              LET'S EXPLORE
              <img
                src={parkImage}
                alt="Forest Icon"
                style={{
                  width: '30px', 
                  height: '30px',
                  marginLeft: '10px', // Add space between text and icon
                }}
              />
            </Button>
          </Box>
        </Banner>
        <InfoCardsHome />
      </Container>
    </Box>
  );
};

export default Home;
