// src/components/ExploreGreenSpace.js

import React from 'react';

const ExploreGreenSpace = () => {
  return (
    <>
    <div>
      <h1>Explore Green Space</h1>
      <p>Here you can explore various green spaces.</p>
    </div>
    
    <div style={{ width: '100%', height: '400px', backgroundColor: '#e0e0e0', marginBottom: '20px' }}>
        <p style={{ textAlign: 'center', paddingTop: '130px' }}>Mapbox Placeholder</p>
    </div>
      
    </>
  );
};

export default ExploreGreenSpace;
